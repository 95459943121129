import React from 'react';
import Slider from 'infinite-react-carousel';
import image1 from '../images/restaurant_1.jpg';
import image2 from '../images/restaurant_2.jpg';
import image3 from '../images/restaurant_3.jpg';
import image4 from '../images/restaurant_4.jpg';

import image1WebP from '../images/restaurant_1.webp';
import image2WebP from '../images/restaurant_2.webp';
import image3WebP from '../images/restaurant_3.webp';
import image4WebP from '../images/restaurant_4.webp';

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  virtualList: true,
  duration: 20,
  arrows: false,
};

const Carousel = ({ data }) => {
  return (
    <div
      data-sal="fade"
      data-sal-duration="500"
      data-sal-delay="100"
      data-sal-easing="ease-in"
    >
      <Slider {...settings}>
        <div>
          <picture>
            <source
              className="slide_img"
              srcSet={image1WebP}
              type="image/webp"
            />
            <source className="slide_img" srcSet={image1} type="image/jpg" />
            <img
              className="slide_img"
              width="692"
              height="443"
              loading="lazy"
              src={image1}
              alt="Restaurant"
            />
          </picture>
        </div>
        <div>
          <picture>
            <source
              className="slide_img"
              srcSet={image2WebP}
              type="image/webp"
            />
            <source className="slide_img" srcSet={image2} type="image/jpg" />
            <img
              className="slide_img"
              width="692"
              height="443"
              loading="lazy"
              src={image2}
              alt="Restaurant"
            />
          </picture>
        </div>
        <div>
          <picture>
            <source
              className="slide_img"
              srcSet={image3WebP}
              type="image/webp"
            />
            <source className="slide_img" srcSet={image3} type="image/jpg" />
            <img
              className="slide_img"
              width="692"
              height="443"
              loading="lazy"
              src={image3}
              alt="Restaurant"
            />
          </picture>
        </div>
        <div>
          <picture>
            <source
              className="slide_img"
              srcSet={image4WebP}
              type="image/webp"
            />
            <source className="slide_img" srcSet={image4} type="image/jpg" />
            <img
              className="slide_img"
              width="692"
              height="443"
              loading="lazy"
              src={image4}
              alt="Restaurant"
            />
          </picture>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
