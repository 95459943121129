import * as React from 'react';
import '../css/styles.css';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Navbar = ({ data }) => {
  const handleMobileCheckbox = event => {
    event.preventDefault();
    const checkbox = document.querySelector('.mobile-checkbox');
    if (checkbox.checked) {
      document.querySelector('.mobile-checkbox').checked = false;
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        className="mobile-checkbox"
        id="nav-toggle"
      ></input>
      <label htmlFor="nav-toggle" className="mobile-button">
        <span className="mobile-icon">&nbsp;</span>
      </label>
      <nav className="nav-menu">
        <ul className="nav-menu-list" id="nav-menu-list">
          {data &&
            data.map(item => (
              <li
                key={item.title}
                className="nav-link-item"
                onClick={handleMobileCheckbox}
              >
                <AnchorLink
                  to={item.link}
                  title={item.title}
                  className="nav-menu-link"
                ></AnchorLink>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
