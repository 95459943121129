import * as React from 'react';
import '../css/styles.css';
import Button from './button';
import Modal from '../components/modal';
import gopi_ramineni from '../images/gopi_ramineni.jpg';
import gopi_ramineniWebP from '../images/gopi_ramineni.webp';

const MeetCeo = ({ data }) => {
  const showModal = event => {
    event.preventDefault();
    document.getElementById(`${data.id}`).classList.add('modal-active');
  };
  return (
    <div className="meet_ceo" id="ceo">
      <div className="container">
        <h1
          className="heading-primary-l mb-5"
          data-sal="slide-up"
          data-sal-duration="200"
          data-sal-delay="150"
          data-sal-easing="ease-in"
        >
          {data.heading}
        </h1>
        <div
          className="grid grid-2 justify-between align-center"
          data-sal="fade"
          data-sal-duration="200"
          data-sal-delay="100"
          data-sal-easing="ease-in"
        >
          <picture>
            <source
              className="img"
              srcSet={gopi_ramineniWebP}
              type="image/webp"
            />
            <source className="img" srcSet={gopi_ramineni} type="image/jpg" />
            <img
              loading="lazy"
              width="600"
              height="600"
              className="img"
              src={gopi_ramineni}
              alt="CEO perfil"
            />
          </picture>
          <div className=" mt-2">
            <h2
              className="heading-secondary-l mb-1"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="100"
              data-sal-easing="ease-in"
            >
              {data.name}
            </h2>
            <h3
              className="subheading-m mb-3"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="150"
              data-sal-easing="ease-in"
            >
              {data.role}
            </h3>
            <div
              className="mb-3"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="200"
              data-sal-easing="ease-in"
            >
              <p className="paragraph mb-1">{data.bio1}</p>
              <p className="paragraph mb-1">{data.bio2}</p>
              <p className="paragraph">{data.bio3}</p>
            </div>
            <div
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="200"
              data-sal-easing="ease-in"
            >
              <Button
                children="Connect on LinkedIn"
                url={data.linkedin}
                buttonClass="btn-outline mr-2"
                iconClass="icon"
              ></Button>
              <button type="submit" className="btn-outline" onClick={showModal}>
                Book a 1:1 Session
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id={data.id}
        title="1:1 Session with Gopi Ramineni"
        email={data.form_email}
        subject={data.form_subject}
      />
    </div>
  );
};

export default MeetCeo;
