import * as React from 'react';
import '../css/styles.css';
import city from '../images/city.mp4';
import cityWebm from '../images/city.webm';
import Header from './header';

const Hero = ({ data }) => {
  return (
    <div className="hero">
      <div className="background-video">
        <video autoPlay muted loop={true} className="video">
          <source src={cityWebm} type="video/webm" />
          <source src={city} type="video/mp4" />
        </video>
      </div>
      <div className="background-overlay"></div>
      <Header data={data}></Header>
      <div className="absolute-center hero-heading">
        <h1
          className="heading-primary-xxl mb-2"
          data-sal="slide-up"
          data-sal-duration="100"
          data-sal-delay="100"
          data-sal-easing="ease-in"
        >
          {data.hero.heading}
        </h1>
        <h2
          className="subheading-xl"
          data-sal="slide-up"
          data-sal-duration="150"
          data-sal-delay="150"
          data-sal-easing="ease-in"
        >
          {data.hero.subheading}
        </h2>
      </div>
    </div>
  );
};

export default Hero;
