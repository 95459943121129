import * as React from 'react';
import '../css/styles.css';

const Card = ({ img, heading1, heading2, text1, text2 }) => {
  return (
    <div
      className="card"
      data-sal="fade"
      data-sal-duration="200"
      data-sal-delay="150"
      data-sal-easing="ease-in"
    >
      <picture>
        <source className="img" srcSet={img.webp} type="image/webp" />
        <source className="img" srcSet={img.jpg} type="image/jpg" />
        <img
          loading="lazy"
          width="340"
          height="340"
          className="img card-img"
          src={img.jpg}
          alt={heading1}
        />
      </picture>
      <div className="card_text mb-4">
        <h2 className="subheading-m">{heading1}</h2>
        <h2 className="subheading-m mb-2">{heading2}</h2>
        <p className="paragraph">{text1}</p>
        <p className="paragraph">{text2}</p>
      </div>
    </div>
  );
};

export default Card;
