import * as React from 'react';
import '../css/styles.css';
import menu_breakfast from '../images/menu_breakfast.jpg';
import menu_breakfastWebP from '../images/menu_breakfast.webp';
import menu_drinks from '../images/menu_drinks.jpg';
import menu_drinksWebP from '../images/menu_drinks.webp';
import menu_lunch from '../images/menu_lunch.jpg';
import menu_lunchWebP from '../images/menu_lunch.webp';

const Menu = () => {
  return (
    <div className="menu">
      <div className="container">
        <div className="grid grid-3 justify-between align-center">
          <picture
            data-sal="fade"
            data-sal-duration="200"
            data-sal-delay="100"
            data-sal-easing="ease-in"
          >
            <source
              className="img"
              srcSet={menu_breakfastWebP}
              type="image/webp"
            />
            <source className="img" srcSet={menu_breakfast} type="image/jpg" />
            <img
              loading="lazy"
              width="363"
              height="530"
              className="img"
              src={menu_breakfast}
              alt="Drinks"
            />
          </picture>
          <picture
            data-sal="fade"
            data-sal-duration="200"
            data-sal-delay="150"
            data-sal-easing="ease-in"
          >
            <source className="img" srcSet={menu_lunchWebP} type="image/webp" />
            <source className="img" srcSet={menu_lunch} type="image/jpg" />
            <img
              loading="lazy"
              width="363"
              height="530"
              className="img"
              src={menu_lunch}
              alt="Drinks"
            />
          </picture>
          <picture
            data-sal="fade"
            data-sal-duration="200"
            data-sal-delay="200"
            data-sal-easing="ease-in"
          >
            <source
              className="img"
              srcSet={menu_drinksWebP}
              type="image/webp"
            />
            <source className="img" srcSet={menu_drinks} type="image/jpg" />
            <img
              loading="lazy"
              width="363"
              height="530"
              className="img"
              src={menu_drinks}
              alt="Drinks"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default Menu;
