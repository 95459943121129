import * as React from 'react';
import '../css/styles.css';
import Button from './button';
import CardIcon from './card_icon';
import magnifying from '../images/magnifying.svg';
import clock from '../images/clock.svg';
import location from '../images/location.svg';
import calendar from '../images/calendar.svg';
import anandhi from '../images/Anandhid.png';
import anandhiWebp from '../images/Anandhid.webp';
import nick from '../images/Nick.png';
import nickWebp from '../images/Nick.webp';

const PresentationSection = ({ data }) => {
  return (
    <div className="presentation_section">
      <div className="container">
        <h1
          className="heading-primary-l mb-5"
          data-sal="slide-up"
          data-sal-duration="200"
          data-sal-delay="150"
          data-sal-easing="ease-in"
        >
          {data.heading}
        </h1>
        <div className="grid-presentation">
          <div>
            <p
              className="paragraph mb-3"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="200"
              data-sal-easing="ease-in"
            >
              {data.text}
            </p>
            <CardIcon
              icon={magnifying}
              text1={data.topic}
              className1="text-bold"
            ></CardIcon>
            <CardIcon
              icon={location}
              text1={data.location}
              className1="text-bold"
            ></CardIcon>
            <CardIcon
              icon={calendar}
              text1={data.date}
              className1="text-bold"
            ></CardIcon>
            <CardIcon
              icon={clock}
              text1={data.time}
              className1="text-bold"
            ></CardIcon>
            <Button
              children={data.cta.text}
              url={data.cta.link}
              buttonClass="btn-outline"
              iconClass="icon"
              title={data.cta.text}
            ></Button>
          </div>
          <div
            className="grid-presentation-leader"
            data-sal="fade"
            data-sal-duration="200"
            data-sal-delay="100"
            data-sal-easing="ease-in"
          >
            <picture>
              <source srcSet={anandhiWebp} type="image/webp" />
              <source srcSet={anandhi} type="image/png" />
              <img
                loading="lazy"
                width="300"
                height="300"
                src={anandhi}
                alt="Anamdi with red background"
              />
            </picture>
            <picture>
              <source srcSet={nickWebp} type="image/webp" />
              <source srcSet={nick} type="image/png" />
              <img
                loading="lazy"
                width="300"
                height="300"
                src={nick}
                alt="Nick with red background"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationSection;
