import * as React from 'react';
import '../css/styles.css';

const CardIcon = ({
  text1,
  text2,
  icon,
  className1,
  className2,
  link,
  linkText,
}) => {
  return (
    <div className="flex justify-start align-center mb-3">
      <img className="icon" src={icon} alt={icon} />
      <div>
        {link && (
          <a
            href={link}
            className={className1}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        )}
        <p className={className1}>{text1}</p>
        {text2 && <p className={className2}>{text2}</p>}
      </div>
    </div>
  );
};

export default CardIcon;
