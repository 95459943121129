import * as React from 'react';
import '../css/styles.css';
import CardLeader from './card_leader';
import donn from '../images/donn_vucovich.jpg';
import pamela from '../images/pamela_hannett.jpg';
import anandhi from '../images/anandhi_narayanan.jpg';
import jim from '../images/jim_rogers.jpg';
import jenny from '../images/Jenny.jpg';
import nick from '../images/Nicholas.jpg';
import donnWebP from '../images/donn_vucovich.webp';
import pamelaWebP from '../images/pamela_hannett.webp';
import anandhiWebP from '../images/anandhi_narayanan.webp';
import jimWebP from '../images/jim_rogers.webp';
import jennyWebP from '../images/Jenny.webp';
import nickWebP from '../images/Nicholas.webp';

const IndustryLeaders = ({ data }) => {
  return (
    <div className="industry-leaders" id="leaders">
      <div className="container">
        <h1
          className="heading-primary-l mb-5"
          data-sal="slide-up"
          data-sal-duration="200"
          data-sal-delay="150"
          data-sal-easing="ease-in"
        >
          {data.heading}
        </h1>
        <div
          className="industry-grid grid grid-6 justify-between"
          data-sal="fade"
          data-sal-duration="100"
          data-sal-delay="100"
          data-sal-easing="ease-in"
        >
          <CardLeader
            leader={data.leaders[0]}
            img={{ jpg: pamela, webp: pamelaWebP }}
            classList="leader active"
          />
          <CardLeader
            leader={data.leaders[1]}
            img={{ jpg: anandhi, webp: anandhiWebP }}
            classList="leader"
          />
          <CardLeader
            leader={data.leaders[2]}
            img={{ jpg: donn, webp: donnWebP }}
            classList="leader"
          />
          <CardLeader
            leader={data.leaders[3]}
            img={{ jpg: jim, webp: jimWebP }}
            classList="leader"
          />
          <CardLeader
            leader={data.leaders[4]}
            img={{ jpg: jenny, webp: jennyWebP }}
            classList="leader"
          />
          <CardLeader
            leader={data.leaders[5]}
            img={{ jpg: nick, webp: nickWebP }}
            classList="leader"
          />
        </div>
      </div>
    </div>
  );
};

export default IndustryLeaders;
