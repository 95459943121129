import * as React from 'react';
import '../css/styles.css';
import logo from '../images/logo.svg';
import salesforce from '../images/salesforce.svg';
import close from '../images/plus.svg';
import Form from './form';

const Modal = ({ id, title, email, subject }) => {
  const hideModal = event => {
    event.preventDefault();
    document.getElementById(`${id}`).classList.remove('modal-active');
    document.querySelector(`#${id} .modal-grid`).style.display = 'grid';
    document.querySelector(`#${id} .modal-message`).style.display = 'none';
  };
  return (
    <div className="modal" id={id}>
      <div className="modal-grid">
        <div className="modal-col-left">
          <div className="modal-logos flex justify-start">
            <a
              href={'https://www.gerent.com/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="gerent-logo"
                src={logo}
                alt="Gerent's Logo on white"
              />
            </a>
            <a
              href={'https://www.salesforce.com/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="salesforce-logo"
                src={salesforce}
                alt="Salesforce's Logo"
              />
            </a>
          </div>
        </div>
        <div className="modal-col-right">
          <div className="container">
            <div className="flex justify-between">
              <div className="heading mb-3">
                <h1 className="heading-secondary-l mb-2" id="heading-modal">
                  {title}
                </h1>
                <p>
                  Interested in booking 1:1 time with a Gerent leader? Please
                  fill the form below to indicate a time you're interested in
                  and we'll reach out to you to confirm their availability.
                </p>
              </div>
              <button type="submit" onClick={hideModal} className="btn-close">
                <img className="icon-close" src={close} alt="close icon" />
              </button>
            </div>
          </div>
          <Form sendTo={email} subject={subject} id={id} />
        </div>
      </div>
      <div className="modal-message">
        <h2 className="heading-secondary-l">
          Thank you for your interest in meeting with us!<br></br> We'll be in
          touch soon.
        </h2>
        <button type="submit" className="btn-primary mt-4" onClick={hideModal}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
