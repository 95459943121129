import * as React from 'react';
import '../css/styles.css';
import Navbar from './navbar';
import logo from '../images/logo.svg';
import logoRed from '../images/gerent-logo.svg';
import salesforce from '../images/salesforce.svg';

const Header = ({ data }) => {
  return (
    <div className="header">
      <header className="nav">
        <div className="flex justify-start">
          <a
            href={'https://www.gerent.com/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="gerent-logo-white"
              src={logo}
              alt="Gerent's Logo on white"
            />
            <img
              className="gerent-logo-red"
              src={logoRed}
              alt="Gerent's Logo on red"
            />
          </a>
          <a
            href={'https://www.salesforce.com/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="salesforce-logo"
              src={salesforce}
              alt="Salesforce's Logo"
            />
          </a>
        </div>
        <Navbar data={data.navigation}></Navbar>
      </header>
    </div>
  );
};

export default Header;
