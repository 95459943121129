import * as React from 'react';
import '../css/styles.css';
import Button from './button';

const Give = ({ data }) => {
  return (
    <div className="background-img-banner" id="give">
      <div className="container">
        <div className="absolute-center hero-heading">
          <h1
            className="heading-primary-xxl mb-2"
            data-sal="slide-up"
            data-sal-duration="200"
            data-sal-delay="150"
            data-sal-easing="ease-in"
          >
            {data.heading}
          </h1>
          <h2
            className="subheading-l mb-3"
            data-sal="slide-up"
            data-sal-duration="200"
            data-sal-delay="200"
            data-sal-easing="ease-in"
          >
            {data.subheading}
          </h2>
          <div
            data-sal="slide-up"
            data-sal-duration="200"
            data-sal-delay="250"
            data-sal-easing="ease-in"
          >
            <Button
              children={data.cta.text}
              url={data.cta.link}
              buttonClass="btn-primary"
              iconClass="icon"
              title="Gerent Gives"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Give;
