import * as React from 'react';
import '../css/styles.css';
import {Link} from 'gatsby';

const Button = ({
                    children,
                    url,
                    activeClassName,
                    partiallyActive,
                    buttonClass,
                    iconClass,
                    icon,
                    title
                }) => {
    return (
        <>
            {url ? (
                <Link
                    to={url}
                    activeClassName={activeClassName}
                    partiallyActive={partiallyActive}
                    className={buttonClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={title}
                >
                    <>{icon ? <img className={iconClass} src={icon} alt={icon}/> : ''}</>
                    {children}
                </Link>
            ) : (
                <button className={buttonClass}>{children}</button>
            )}
        </>
    );
};

export default Button;
