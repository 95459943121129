import * as React from 'react';
import '../css/styles.css';
import Card from './card';
import card_2 from '../images/live_stream.jpg';
import card_2WebP from '../images/live_stream.webp';
import card_1 from '../images/practical_swag.jpg';
import card_1WebP from '../images/practical_swag.webp';
import card_3 from '../images/margaritas.jpg';
import card_3WebP from '../images/margaritas.webp';

const CardsSection = ({ data }) => {
  return (
    <div className="cards_section">
      <div className="container">
        <h1
          className="heading-primary-l mb-5"
          data-sal="slide-up"
          data-sal-duration="200"
          data-sal-delay="150"
          data-sal-easing="ease-in"
        >
          {data.heading}
        </h1>
        <div className="cards-grid">
          <Card
            img={{ jpg: card_1, webp: card_1WebP }}
            heading1={data.cards[0].heading1}
            heading2={data.cards[0].heading2}
            text1={data.cards[0].text1}
            text2={data.cards[0].text2}
          />
          <Card
            img={{ jpg: card_2, webp: card_2WebP }}
            heading1={data.cards[1].heading1}
            heading2={data.cards[1].heading2}
            text1={data.cards[1].text1}
            text2={data.cards[1].text2}
          />
          <Card
            img={{ jpg: card_3, webp: card_3WebP }}
            heading1={data.cards[2].heading1}
            heading2={data.cards[2].heading2}
            text1={data.cards[2].text1}
            text2={data.cards[2].text2}
          />
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
