import * as React from 'react';
import '../css/styles.css';
import Carousel from './carousel';
import CardIcon from './card_icon';
import link from '../images/link.svg';
import clock from '../images/clock.svg';
import location from '../images/location.svg';
import calendar from '../images/calendar.svg';

const Taco = ({ data }) => {
  return (
    <div className="taco-banner" id="taco">
      <div className="container">
        <div className="grid grid-2 justify-between align-center">
          <div className="col-left">
            <h1
              className="heading-primary-l mb-3"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="150"
              data-sal-easing="ease-in"
            >
              {data.heading}
            </h1>
            <p
              className="paragraph mb-2"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="200"
              data-sal-easing="ease-in"
            >
              {data.text1}
            </p>
            <p
              className="paragraph"
              data-sal="slide-up"
              data-sal-duration="200"
              data-sal-delay="200"
              data-sal-easing="ease-in"
            >
              {data.text2}
            </p>
            <div
              className="margs-mingle-details"
              data-sal="fade"
              data-sal-duration="200"
              data-sal-delay="200"
              data-sal-easing="ease-in"
            >
              <CardIcon
                icon={link}
                link="https://www.buenavidacantina.com/"
                linkText={data.link}
                className1="text-underline"
              ></CardIcon>
              <CardIcon
                icon={location}
                text1={data.location1}
                text2={data.location2}
                className1="text-bold"
                className2="text-italic"
              ></CardIcon>
              <CardIcon
                icon={calendar}
                text1={data.date}
                className1="text-bold"
              ></CardIcon>
              <CardIcon
                icon={clock}
                text1={data.time}
                className1="text-bold"
              ></CardIcon>
            </div>
          </div>
          <div className="col-right">
            <Carousel></Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Taco;
