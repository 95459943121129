import * as React from 'react';
import '../css/styles.css';

const FORM_HANDLER_URL = '/.netlify/functions/handleCalendar';

const Form = ({ sendTo, subject, id }) => {
  const handleFormSubmission = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    console.log(formData);
    fetch(FORM_HANDLER_URL, {
      method: 'post',
      body: JSON.stringify(Object.fromEntries(formData)),
    })
      .then()
      .catch()
      .finally();

    document.querySelector(`#${id} .modal-grid`).style.display = 'none';
    document.querySelector(`#${id} .modal-message`).style.display = 'block';
    event.target.reset();
  };
  return (
    <div className="container">
      <form method="post" onSubmit={handleFormSubmission} className="form">
        <div className="flex justify-between">
          <input
            type="text"
            name="name"
            id="name"
            placeholder="First Name"
            className="input-field mr-2"
            required
          />
          <input
            type="text"
            name="last_name"
            id="last_name"
            placeholder="Last Name"
            className="input-field"
            required
          />
        </div>
        <div className="flex justify-between">
          <input
            type="phone"
            name="phone"
            id="phone"
            placeholder="Phone Number"
            className="input-field mr-2"
          />
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email Address"
            className="input-field"
            required
          />
        </div>
        <div className="flex justify-between">
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Title"
            className="input-field mr-2"
          />
          <input
            type="text"
            name="company"
            id="company"
            placeholder="Company"
            className="input-field"
          />
        </div>
        <div className="mt-1 mb-1">
          <p>Provide your availability (check all that apply):</p>
        </div>
        <div className="flex justify-between availability mb-1">
          <fieldset className="input-field input-multiselect mr-2" name="20">
            <legend>September 20</legend>
            <div className="radio-group">
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_1"
                  name="date_20_time_1"
                  value="07:00am - 08:00am"
                  defaultChecked
                  className="radio-input"
                />
                <label htmlFor="20_1">07:00am - 08:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_2"
                  name="date_20_time_2"
                  value="08:00am - 09:00am"
                  className="radio-input"
                />
                <label htmlFor="20_2">08:00am - 09:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_3"
                  name="date_20_time_3"
                  value="09:00am - 10:00am"
                  className="radio-input"
                />
                <label htmlFor="20_3">09:00am - 10:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_4"
                  name="date_20_time_4"
                  value="10:00am - 11:00am"
                  className="radio-input"
                />
                <label htmlFor="20_4">10:00am - 11:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="5"
                  name="date_20_time_5"
                  value="11:00am - 12:00pm"
                  className="radio-input"
                />
                <label htmlFor="20_5">11:00am - 12:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_6"
                  name="date_20_time_6"
                  value="12:00pm - 01:00pm"
                  className="radio-input"
                />
                <label htmlFor="20_6">12:00pm - 01:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_7"
                  name="date_20_time_7"
                  value="01:00pm - 02:00pm"
                  className="radio-input"
                  disabled={id === 'leader_3' ? true : false}
                />
                <label htmlFor="20_3">01:00pm - 02:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_8"
                  name="date_20_time_8"
                  value="02:00pm - 03:00pm"
                  className="radio-input"
                  disabled={id === 'leader_3' ? true : false}
                />
                <label htmlFor="20_8">02:00pm - 03:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="20_9"
                  name="date_20_time_9"
                  value="03:00pm - 04:00pm"
                  className="radio-input"
                />
                <label htmlFor="20_9">03:00pm - 04:00pm</label>
              </div>
            </div>
          </fieldset>
          <fieldset className="input-field input-multiselect" name="21">
            <legend>September 21</legend>
            <div className="radio-group">
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_1"
                  name="date_21_time_1"
                  value="07:00am - 08:00am"
                  defaultChecked
                  className="radio-input"
                />
                <label htmlFor="21_1">07:00am - 08:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_2"
                  name="date_21_time_2"
                  value="08:00am - 09:00am"
                  className="radio-input"
                />
                <label htmlFor="21_2">08:00am - 09:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_3"
                  name="date_21_time_3"
                  value="09:00am - 10:00am"
                  className="radio-input"
                />
                <label htmlFor="21_3">09:00am - 10:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_4"
                  name="date_21_time_4"
                  value="10:00am - 11:00am"
                  className="radio-input"
                />
                <label htmlFor="21_4">10:00am - 11:00am</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_5"
                  name="date_21_time_5"
                  value="11:00am - 12:00pm"
                  className="radio-input"
                />
                <label htmlFor="21_5">11:00am - 12:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_6"
                  name="date_21_time_6"
                  value="12:00pm - 01:00pm"
                  className="radio-input"
                />
                <label htmlFor="21_6">12:00pm - 01:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_7"
                  name="date_21_time_7"
                  value="01:00pm - 02:00pm"
                  className="radio-input"
                />
                <label htmlFor="21_7">01:00pm - 02:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_8"
                  name="date_21_time_8"
                  value="02:00pm - 03:00pm"
                  className="radio-input"
                />
                <label htmlFor="21_8">02:00pm - 03:00pm</label>
              </div>
              <div className="radio-item">
                <input
                  type="checkbox"
                  id="21_9"
                  name="date_21_time_9"
                  value="03:00pm - 04:00pm"
                  className="radio-input"
                />
                <label htmlFor="21_9">03:00pm - 04:00pm</label>
              </div>
            </div>
          </fieldset>
        </div>
        <div className="modal-button">
          <button type="submit" className="btn-form" disabled>
            Book Now
          </button>
        </div>
        <div>
          <input
            type="email"
            name="send"
            value={sendTo}
            id="send"
            className="hidden"
            readOnly
          />
          <input
            type="text"
            name="subject"
            value={subject}
            id="subject"
            className="hidden"
            readOnly
          />
        </div>
      </form>
    </div>
  );
};

export default Form;
