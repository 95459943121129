import * as React from 'react';
import '../css/styles.css';
import Button from './button';
import Modal from '../components/modal';

const CardLeader = ({ leader, img, classList }) => {
  const modalTitle = `1:1 Session with ${leader.name}`;
  const onclickHandler = event => {
    event.preventDefault();
    const leaders = document.querySelectorAll('.leader');
    leaders.forEach(el => {
      el.classList.remove('active');
    });

    event.currentTarget.parentElement.classList.add('active');
  };
  const showModal = event => {
    event.preventDefault();
    document.getElementById(`${leader.id}`).classList.add('modal-active');
  };
  return (
    <div className={classList}>
      <picture onClick={onclickHandler}>
        <source className="leader-img" srcSet={img.webp} type="image/webp" />
        <source className="leader-img" srcSet={img.jpg} type="image/jpg" />

        <img
          loading="lazy"
          width="550"
          height="600"
          className="leader-img"
          src={img.jpg}
          alt={leader.name}
        />
      </picture>
      <div className="leader-details mt-2">
        <h2 className="heading-secondary-l mb-1">{leader.name}</h2>
        <h3 className="subheading-m mb-3">{leader.role}</h3>
        <p className="paragraph mb-3">{leader.bio}</p>
        <div>
          <Button
            children="Connect on LinkedIn"
            url={leader.linkedin}
            buttonClass="btn-outline mr-2"
            iconClass="icon"
          ></Button>
          <button type="submit" className="btn-outline" onClick={showModal}>
            Book a 1:1 Session
          </button>
        </div>
      </div>
      <Modal
        id={leader.id}
        title={modalTitle}
        email={leader.form_email}
        subject={leader.form_subject}
      />
    </div>
  );
};

export default CardLeader;
