import * as React from 'react';
import content from '../data/content';
import '@fontsource/roboto';
import favicon from '../images/gerent_favicon.png';
import prevImg from '../images/dreamforce_prev.jpg';
import Hero from '../components/hero';
import Taco from '../components/taco';
import Menu from '../components/menu';
import MeetCeo from '../components/meet_ceo';
import IndustryLeaders from '../components/industry_leaders';
import CardsSection from '../components/cards_section';
import PresentationSection from '../components/presentation_section';
import Give from '../components/give';
import Footer from '../components/footer';
import Head from '../components/head';

const IndexPage = () => {
  return (
    <>
      <Head
        title="Dreamforce 2022 | Gerent"
        description="Join Gerent at Dreamforce 2022 for delicious food and drinks at Buena Vida Cantina (860 Folsom St.), 7 minutes from Moscone."
        favicon={favicon}
        img={prevImg}
      />
      <main>
        <Hero data={content} />
        <Taco data={content.banner_taco} />
        <Menu></Menu>
        <MeetCeo data={content.meet_ceo} />
        <IndustryLeaders data={content.industry_leaders} />
        <PresentationSection data={content.presentation} />
        <CardsSection data={content.cards_section} />
        <Give data={content.banner_give} />
        <Footer data={content.footer} />
      </main>
    </>
  );
};

export default IndexPage;
