import * as React from 'react';
import '../css/styles.css';
import logo from '../images/gerent-logo.svg';
import linkedin from '../images/linkedin.svg';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import youtube from '../images/youtube.svg';

const Footer = ({ data }) => {
  return (
    <footer>
      <div className="container">
        <div
          className="footer-details"
          data-sal="fade"
          data-sal-duration="200"
          data-sal-delay="100"
          data-sal-easing="ease-in"
        >
          <a
            href={'https://www.gerent.com/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              width="220"
              height="72"
              className="footer-logo"
              src={logo}
              alt="Gerent's Logo"
            />
          </a>
          <div className="social">
            <a
              href={data.social[0].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={linkedin} alt="Linkedin logo" />
            </a>
            <a
              href={data.social[1].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={facebook} alt="Facebook logo" />
            </a>
            <a
              href={data.social[2].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={twitter} alt="Twitter logo" />
            </a>
            <a
              href={data.social[3].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icon" src={youtube} alt="Youtube logo" />
            </a>
          </div>
          <p>{data.email}</p>
          <p>{data.phone}</p>
          <div className="address">
            <p>{data.address1}</p>
            <p>{data.address2}</p>
          </div>
        </div>
        <p
          className="copyright"
          data-sal="fade"
          data-sal-duration="200"
          data-sal-delay="150"
          data-sal-easing="ease-in"
        >
          {data.copyright}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
